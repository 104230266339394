import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/community-portal/community-portal/src/modules/layouts/mdx_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Indent = makeShortcode("Indent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Security`}</h1>
    <hr></hr>
    <h3>{`How the Maker Protocol handles the security of oracles`}</h3>
    <p>{`The oracle system for the Maker Protocol uses decentralized reporting to defend against fraudulent price data.`}</p>
    <p>{`There are multiple organizations and individuals who report price-data, they are called Feeds.`}</p>
    <p>{`At the launch of Multi-Collateral Dai, oracles received data from a total of 20 Feeds which consisted of 15 individuals and five public organizations.`}</p>
    <p>{`Oracles use the median of the reported prices for each asset as the reference price. Using a median instead of an average makes it harder to manipulate the reference price since control over half of the data providers is needed in order for a fraudulent price to be pushed through.`}</p>
    <p>{`Additionally, using a median naturally filters out irregular price data.`}</p>
    <h2>{`Feeds`}</h2>
    <Indent pad={2} mdxType="Indent">
      <p>{`Feeds are a mix of pseudonymous individuals and public organizations. Individuals consist of people internal to Maker, influential people in the greater crypto community, as well as some community members.`}</p>
      <p>{`Organizations involved in being Feeds at the launch of MCD can be found in the ratified `}<a parentName="p" {...{
          "href": "https://vote.makerdao.com/polling-proposal/qmpem83sxjjwe1jvn2csdtetn4r3j95ejuzpbmtxef4lu5"
        }}>{`DeFi Feeds proposal.`}</a></p>
    </Indent>
    <h2>{`Becoming a Feed`}</h2>
    <Indent pad={2} mdxType="Indent">
      <p>{`All new Feeds go through `}<a parentName="p" {...{
          "href": "https://community-development.makerdao.com/governance/governance"
        }}>{`MakerDAO’s governance`}</a>{` in order to be added in. There is currently no formal way for Feeds to be added to the Maker Protocol. `}</p>
      <p>{`As of October 7th, 2019, the ratified `}<a parentName="p" {...{
          "href": "https://vote.makerdao.com/polling-proposal/qmas1bqrquo2h41qv4fa8hpek9ukb7dlwtpkpn62r5hhmq"
        }}>{`Oracle Team Mandate`}</a>{` grants the Interim Oracle Team the responsibility of being the intermediary between the Feeds and governance. In the coming months, the process of becoming a Feed will become more clear.`}</p>
    </Indent>
    <h2>{`Oracle Security Module`}</h2>
    <Indent pad={2} mdxType="Indent">
      <p>{`The `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/smart-contract-modules/oracle-module/oracle-security-module-osm-detailed-documentation"
        }}>{`Oracle Security Module(OSM)`}</a>{` safeguards the process by delaying price-feed data for one hour.`}</p>
      <p>{`Oracle Security Modules (OSMs) delay the publishing of new reference prices for a predefined set of time. This parameter is called the Oracle Security Module Delay and was set to be one hour at the launch of MCD. `}</p>
      <p>{`This allows `}<a parentName="p" {...{
          "href": "/learn/governance/mkrtokens.mdx"
        }}>{`MKR token`}</a>{` holders and other stakeholders the time to react to bugs or attacks on the Oracles. An OSM is active on each Oracle in the Maker Protocol.`}</p>
      <p>{`This allows MKR governors and other stakeholders the time to identify bugs or attacks on the price-feed system. An OSM is active on all Oracles in the Maker Protocol.`}</p>
    </Indent>
    <h2>{`Medianizer`}</h2>
    <Indent pad={2} mdxType="Indent">
      <p>{`A Medianizer is a type of smart-contract in the Maker Protocol’s Oracle system that collects price-data from Feeds and calculates a reference price by calculating a median.`}</p>
      <p>{`The Medianizer maintains a white-list of Feeds that can be controlled by MakerDAO governance. Every time a new set of price updates is received, the reference price is recalculated and queued into the Oracle Security Module which publishes the price after a delay period.`}</p>
      <p>{`Each time a relayer pushes a new set of medians to a Medianizer, it recalculates the median and updates the queued reference price in the Oracle Security Module(OSM). The OSM applies a time-delay and acts as the final publisher of the reference price.`}</p>
      <p>{`For technical documentation, please visit the `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/smart-contract-modules/oracle-module/median-detailed-documentation"
        }}>{`Medianizer`}</a>{` section of our `}<a parentName="p" {...{
          "href": "https://docs.makerdao.com/"
        }}>{`Documentation Portal.`}</a></p>
    </Indent>
    <h2>{`Secure Scuttlebutt Network`}</h2>
    <Indent pad={2} mdxType="Indent">
      <p>{`Secure Scuttlebutt is a database protocol for unforgeable append-only message feeds. For more information on how a Secure Scuttlebutt Network works visit this `}<a parentName="p" {...{
          "href": "https://scuttlebot.io/more/protocols/secure-scuttlebutt.html"
        }}>{`informative page`}</a>{` on `}<a parentName="p" {...{
          "href": "https://scuttlebot.io/"
        }}>{`scuttlebot.io.`}</a></p>
    </Indent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      